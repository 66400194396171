<template>
  <top-header-theme0 v-if="kindTheme===0" />
  <top-header-theme1 v-else-if="kindTheme===1" />
  <top-header-theme2 v-else-if="kindTheme === 2" />
</template>

<script>
export default {
  name: 'TopHeader',
  components: {
    TopHeaderTheme0: () => import('@/components/content/TopHeader/TopHeaderTheme0'),
    TopHeaderTheme1: () => import('@/components/content/TopHeader/TopHeaderTheme1'),
    TopHeaderTheme2: () => import('@/components/content/TopHeader/TopHeaderTheme2'),
  },
  props: {
    kindTheme: {
      type: Number,
      default: 0,
    },
  },
};
</script>
